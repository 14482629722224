.credit-button {
  position: fixed;
  bottom: 0px;
  right: 20px;
  background-color: transparent; /* No background color */
  color: rgb(0, 0, 0); /* Text color */
  padding: 5px 45px; /* Padding */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 8px; /* Font size reduced by 50% from 16px */
  font-family: Calibri, sans-serif;
  z-index: 1000; /* Ensure it stays on top */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  border: none; /* Remove border */
}



.credit-button:hover {
  background-color: rgba(89, 89, 90, 0.1); /* Light blue background on hover */
}


/* Style for the popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the popup is on top of everything */
}
/* Style for the popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the popup is on top of everything */
}

/* Style for the popup content */
.popup-content {
  background-color: white;
  padding: 30px;
  width: 50%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  background-size: cover; /* Make sure the background image covers the whole area */
}

/* Style for the close button (blue and smaller) */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: blue;
  color: white;
  border: none;
  font-size: 16px; /* Make the font smaller */
  padding: 4px 8px; /* Smaller padding */
  cursor: pointer;
  border-radius: 50%;
}

/* Styling for table elements */
.popup-content table {
  width: 100%;
  text-align: left;
}

/* Make names in the first column black */
.popup-content table td:first-child {
  color: black;
}

.popup-content table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.popup-content h3 {
  margin-bottom: 20px;
}

/* Add spacing between rows */
.popup-content tr {
  height: 35px;
}
